<template>
  <div class="container">
    <div class="product">
        <div class="product-title">产品优势</div>
        <div class="product-list">
          <div class="product-item" v-for="(item, index) in productiItemList" :key="index">
            <div class="product-item-icon">
              <img :src="item.url">
            </div>
            <div class="product-item-text">
              <div class="product-item-text-t">{{item.textT}}</div>
              <div class="product-item-text-b">{{item.textB}}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      productiItemList: [
        {
          url: require('assets/images/join/product01.png'),
          textT: '提供技术支持',
          textB: '24小时技术在线维护'
        },
        {
          url: require('assets/images/join/product02.png'),
          textT: '独立域名',
          textB: '独立后台 自由管理'
        },
        {
          url: require('assets/images/join/product03.png'),
          textT: '分站成本低',
          textB: '提供服务器、域名 维护、升级服务'
        },
        {
          url: require('assets/images/join/product04.png'),
          textT: '建站速度快',
          textB: '10分钟即可实现建站 推广运营网站'
        },
        {
          url: require('assets/images/join/product05.png'),
          textT: '网站API接口',
          textB: '免费提供 无需任何费用'
        },
        {
          url: require('assets/images/join/product06.png'),
          textT: '提供稳定安全',
          textB: '低价的快递费 礼品货源'
        },
        {
          url: require('assets/images/join/product07.png'),
          textT: '利润直达账户',
          textB: '提现 24小时内到账'
        },
        {
          url: require('assets/images/join/product08.png'),
          textT: '前景可观',
          textB: '客源稳定 收入稳定'
        }
      ]
    }
  }
}
</script>

<style scoped src="styles/views/join/joinproduct.css">
</style>
