<template>
  <div>
    <nav-bar/>
    <join-banner/>
    <join-product/>
    <join-step/>
    <join-profit/>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import JoinBanner from 'views/join/childComps/JoinBanner'
import JoinProduct from 'views/join/childComps/JoinProduct'
import JoinStep from 'views/join/childComps/JoinStep'
import JoinProfit from 'views/join/childComps/JoinProfit'

export default {
  components: {
    NavBar,
    JoinBanner,
    JoinStep,
    JoinProduct,
    JoinProfit
  }
}
</script>

<style scoped>
</style>
