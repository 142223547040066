<template>
  <div class="container-full banner">
    <div class="container">
      <div class="content">
        <div class="content-title">分站开通·拥有属于自己的网站</div>
        <div class="sub-title">网站完全独立控制，管理操作简单；全天24小时自动售单，无需人工干预。</div>
        <div class="consult_btn" @click="consultImmediately">立即咨询</div>
        <div class="advantage">
          <div class="advantage-item">
            <div class="advantage_item_icon">
              <img src="~assets/images/join/chengben.png">
            </div>
            <div class="advantage-item-text">成本低</div>
          </div>
          <div class="advantage-item">
            <div class="advantage_item_icon">
              <img src="~assets/images/join/jianzhan.png">
            </div>
            <div class="advantage-item-text">建站快</div>
          </div>
          <div class="advantage-item">
            <div class="advantage_item_icon">
              <img src="~assets/images/join/shangxian.png">
            </div>
            <div class="advantage-item-text">上线快</div>
          </div>
          <div class="advantage-item">
            <div class="advantage_item_icon">
              <img src="~assets/images/join/weihu.png">
            </div>
            <div class="advantage-item-text">维护快</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    consultImmediately () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    }
  }
}
</script>

<style scoped src="styles/views/join/joinbanner.css">
</style>
